<template>
  <div>
    <Loading :loading="isLoading"></Loading>
    <v-container class="mw-1150">
      <BreadCrumbs :items="items"></BreadCrumbs>
      <v-layout v-if="model" class="mt-5" wrap>
        <v-flex xs12 sm12 md8 lg8 class="mb-3 mb-md-0">
          <div class="v-html" v-html="model.course_details_header"></div>
        </v-flex>
        <v-flex xs12 sm12 md4 lg4 class="pl-0 pl-md-3 mb-4 mb-md-0">
          <CourseSidePanel :model="model"></CourseSidePanel>
        </v-flex>
      </v-layout>
      <div
        v-if="model"
        class="media-article mt-6"
        v-html="model.course_details"
      ></div>
    </v-container>
  </div>
</template>

<script>
import CourseSidePanel from "../../components/Course/CourseSidePanel.vue";
import Loading from "../../components/Loading.vue";
import BreadCrumbs from "../../components/BreadCrumbs.vue";
import profileImg from "../../assets/icon/course/profile-add.png";
export default {
  components: {
    Loading,
    BreadCrumbs,
    CourseSidePanel,
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },
  async created() {
    const courseId = this.$route.params.id;
    this.isLoading = true;
    if (courseId) {
      const payload = {
        courseId: courseId,
        user_id: this.user ? this.user.id : "",
      };
      await this.$store.dispatch("Course/show", payload).then((res) => {
        this.model = res;
        this.items.push({
          text: res.name,
          disabled: true,
        });
      });
    }
    this.isLoading = false;
  },
  data() {
    return {
      model: null,
      profileImg: profileImg,
      isLoading: false,
      items: [
        {
          text: "หน้าแรก",
          disabled: false,
          href: "/",
        },
        {
          text: "หลักสูตรอบรม",
          disabled: false,
          href: "/course",
        },
      ],
    };
  },
};
</script>

<style scoped>
.v-html >>> img {
  width: 100% !important;
  height: auto !important;
}
.v-html >>> p {
  margin-bottom: 0px !important;
}
.v-html >>> iframe {
  width: 100% !important;
  height: auto !important;
  aspect-ratio: 1.78341013825;
}
@media only screen and (max-width: 768px) {
  .v-html >>> img {
    width: 100% !important;
    height: auto !important;
  }
  .v-html >>> iframe {
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 1.78341013825;
  }
}
</style>
